import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Table } from "react-bootstrap";

// Initial Data is no longer used as we're fetching data from the backend
const CRUDComponent = () => {
  // State hooks
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  // Fetch data from backend on component mount
  useEffect(() => {
    fetch("/api/registration")
      .then((response) => response.json())
      .then((data) => setData(data));
  }, []);

  // Add or Edit data
  const handleSave = () => {
    if (editData) {
      // Update data
      fetch(`/api/registration/${editData.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email }),
      })
        .then((response) => response.json())
        .then((updatedData) => {
          setData(data.map((item) => (item.id === updatedData.id ? updatedData : item)));
          resetForm();
          setShowModal(false);
        });
    } else {
      // Add new data
      fetch("/api/registration", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email }),
      })
        .then((response) => response.json())
        .then((newData) => {
          setData([...data, newData]);
          resetForm();
          setShowModal(false);
        });
    }
  };

  // Handle edit
  const handleEdit = (item) => {
    setEditData(item);
    setName(item.name);
    setEmail(item.email);
    setShowModal(true);
  };

  // Handle delete
  const handleDelete = (id) => {
    fetch(`http://localhost:5000/api/registration/${id}`, {
      method: "DELETE",
    })
      .then(() => {
        setData(data.filter((item) => item.id !== id));
      });
  };

  // Reset form state
  const resetForm = () => {
    setEditData(null);
    setName("");
    setEmail("");
  };

  return (
    <div className="container">
      <h1>CRUD Operations with React</h1>

      {/* Button to open Modal */}
      <Button variant="primary" onClick={() => setShowModal(true)}>
        Add New Entry
      </Button>

      {/* Table */}
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.email}</td>
              <td>
                <Button variant="warning" onClick={() => handleEdit(item)}>
                  Edit
                </Button>{" "}
                <Button variant="danger" onClick={() => handleDelete(item.id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Add/Edit */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editData ? "Edit Entry" : "Add New Entry"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter name"
              />
            </Form.Group>
            <Form.Group controlId="formEmail" className="mt-2">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CRUDComponent;
