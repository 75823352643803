import React from 'react';
import Carousel from '../layout/Carousel';
import About from '../sections/About';
import WhyChooseUs from '../sections/WhyChooseUs';
import ContactUs from '../sections/ContactUs';
import OurServices from '../sections/OurServices';

function Home() {
  return (
    <>
      <Carousel />
      <About />
      <WhyChooseUs />
      <OurServices />
      <ContactUs />
    </>
  );
}

export default Home;
