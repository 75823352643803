// import React, { useEffect } from "react";
import "../assets/css/sections.css";
import { Link } from "react-router-dom";
import { DropdownToggle } from "react-bootstrap";
import { useState } from "react";

function Header() {
  //====== useEffect to toggle Dropdown ======
  // useEffect(() => {
  //   const bootstrap = require('bootstrap');
  //   const dropdownElementList = document.querySelectorAll('.dropdown-toggle');
  //   dropdownElementList.forEach((dropdownToggleEl) =>
  //     new bootstrap.Dropdown(dropdownToggleEl)
  //   );
  // }, []);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        {/* Logo */}
        <Link to="/" className="navbar-brand">
          <img
            src="https://mdbcdn.b-cdn.net/img/logo/mdb-transaprent-noshadows.webp"
            height="15"
            alt="MDB Logo"
            loading="lazy"
          />
        </Link>

        {/* Toggle Button for Mobile */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar Links */}
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="navbar-nav me-auto mb-2 mb-lg-0">
            <Link to="/" className="nav-link">
              Home
            </Link>
            <Link to="/whychooseus" className="nav-link">
              Why Choose Us
            </Link>
            <Link to="/ourservices" className="nav-link">
              Our Services
            </Link>
            <Link to="/aboutus" className="nav-link">
              About Us
            </Link>
            <Link to="/contactus" className="nav-link">
              Contact Us
            </Link>
            <Link to="/news" className="nav-link">
              News
            </Link>

            {/* Dropdown Menu */}
            {/* Using useEffect without hover Effect */}
            {/* <div className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="conceptsDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Concepts
              </a>
              <div className="dropdown-menu" aria-labelledby="conceptsDropdown">
                <Link to="/usestate" className="dropdown-item">
                  useState
                </Link>
              </div>
            </div> */}

            <div className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="conceptsDropdown"
                role="button"
              >
                Concepts
              </a>
              <div className="dropdown-menu" aria-labelledby="conceptsDropdown">
                <Link to="/usestate" className="dropdown-item">
                  useState
                </Link>
              </div>
            </div>
            <Link to="/crud" className="nav-link">CRUD Form</Link>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <a className="text-reset me-3" href="#">
            <i className="fas fa-shopping-cart"></i>
          </a>

          <div className="dropdown">
            <a
              data-mdb-dropdown-init
              className="text-reset me-3 dropdown-toggle hidden-arrow"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              aria-expanded="false"
            >
              <i className="fas fa-bell"></i>
              <span className="badge rounded-pill badge-notification bg-danger">
                1
              </span>
            </a>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <li>
                <a className="dropdown-item" href="#">
                  Some news
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Another news
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </li>
            </ul>
          </div>

          <div className="dropdown">
            <a
              data-mdb-dropdown-init
              className="dropdown-toggle d-flex align-items-center hidden-arrow"
              href="#"
              id="navbarDropdownMenuAvatar"
              role="button"
              aria-expanded="false"
            >
              <img
                src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                className="rounded-circle"
                height="25"
                alt="Black and White Portrait of a Man"
                loading="lazy"
              />
            </a>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdownMenuAvatar"
            >
              <li>
                <a className="dropdown-item" href="#">
                  My profile
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Settings
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
