import './App.css';
import Home from './pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';  // Make sure this is included
import OurServices from './pages/OurServices';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import WhyChooseUs from './sections/WhyChooseUs';
import About from './sections/About';
import ContactUs from './sections/ContactUs';
import News from './pages/News';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import UseStateComponent from './components/useStateComponent';
import CRUDComponent from './components/CRUDComponent';

function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
        <Header />
        <Routes>
            <Route path = '/' element = {<Home />}></Route>
            <Route path = '/ourservices' element = {<OurServices />}></Route>
            <Route path = '/whychooseus' element = {<WhyChooseUs />}></Route>
            <Route path = '/aboutus' element = {<About />}></Route>
            <Route path = '/contactus' element = {<ContactUs />}></Route>
            <Route path = '/news' element = {<News />}></Route>
            <Route path = '/usestate' element = {<UseStateComponent />}></Route>
            <Route path = '/crud' element = {<CRUDComponent />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
