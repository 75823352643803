import React, { useState } from "react";

function UseStateComponent() {
  const [counter, setCounter] = useState(0);

  function incrementCount(){
    setCounter(prevCount => prevCount + 1);
  }

  function decrementCount(){
    setCounter(prevCount => prevCount - 1);
  }

  function resetCount(){
    setCounter(0);
  }

  return (
    <div>
      <h1>useState Concept</h1>
      <div className="counter-container">
        <h1 className="counter-header">Counter</h1>
        <div className="counter-box">
          <h2 className="counter-value">{counter}</h2>
        </div>
        <div className="buttons-container">
          <button onClick={incrementCount} className="counter-button">Increment</button>
          <button onClick={decrementCount} className="counter-button">Decrement</button>
          <button onClick={resetCount} className="counter-button">Reset</button>
        </div>
      </div>
    </div>
  );
}

export default UseStateComponent;
